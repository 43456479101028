/*!
 * Faros QB Bundle
 */
$(function() {
    $('body').on('click', '[data-rel-querymodal="modal-query-params"]', function(e) {
        e.preventDefault();
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            success: function(data) {
              if (data.showModal) {
                $('#modal-query-params .modal-body').html(data.html);
                $('#modal-query-params').modal();
                $('#modal-query-params .select2').trigger('init.select2');
              } else if (data.url) {
                window.location = data.url;
              }
            }
        });
    });
    $('body').on('submit', '#modal-query-params form', function (e) {
        $('#modal-query-params').modal('hide');
        e.preventDefault();
        var postObject = $('#modal-query-params form').serializeObject();
        postObject[$(this).attr('name')] = $(this).val();
        $.ajax({
            url: $('#modal-query-params form').attr('action'),
            type: 'POST',
            data: postObject,
            success: function(data) {
              // If Form is invalid
              if (data.showModal) {
                $('#modal-query-params .modal-body').html(data.html);
                $('#modal-query-params').modal('show');
                $('#modal-query-params .select2').trigger('init.select2');
              }
              else if (data.url) {
                window.location = data.url;
              }
            }
        });
    });
});
