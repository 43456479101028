/*!
 * Faros Admin
 */
$(function () {

    /**
     * Listening html5 events
     *
     * If invalid fields we go to good tab if element is in a tab
     */
    $('.tab-content :input').on('invalid', function(e){
        var $target = $(e.currentTarget),
            $tab = $target.closest('.tab-content div.tab-pane');
        if ($tab && !$tab.hasClass('active')) {
            $('[href="#' + $tab.prop('id') + '"]').tab('show');
        }
    });

    $('a[data-toggle="tab"]').on('show.bs.tab', function(e) {
        window.location.hash = 'tab_' + $(e.target).attr('href').substr(1);
        $(this).parents('.panel-heading').find('.nav-tabs .active').each(function() {
            $(this).removeClass('active');
        });
    });
    if (location.hash !== '') {
        $('a[href="' + location.hash.replace('tab_','') + '"]').tab('show');
    }

    // Change tab on hashchange
    window.addEventListener('hashchange', function() {
      var changedHash = window.location.hash;
      changedHash && $('ul.nav a[href="' + changedHash + '"]').tab('show');
    }, false);

    if ($('.tab-pane .has-error').size() > 0) {
        var panel = $('.tab-pane .has-error').first().parents('.tab-pane').prop('id');
        $('a[href="#'+ panel +'"]').tab('show');
    };

    $(document).on('click', '#filterbar-trigger', function() {
        $('#filterbar').toggleClass('hide');
    });

    $(document).on('click', '.toggle-status', function() {
        var elt = $('#' + $(this).data('toggle'));
        elt.val($(this).data('status'));
        elt.parents('form').submit();
    });

    /* Default class modification */
    $.extend( jQuery.fn.DataTable.ext.classes, {
        sLengthSelect: "form-control input-sm length-selector",
        sFilterInput:  "form-control input-lg pull-right",
        sProcessing: 'datatable-loading pull-right'
    });

    var dataTablesOptions = {
        "dom": "<'row'<'col-xs-6'i><'col-xs-6'fr>>t<'row'<'col-xs-3'l><'col-xs-9'p>>",
        "renderer": 'bootstrap',
        "simpleDom": "t",
        "paginationType": "full_numbers",
        "processing": true,
        "serverSide": true,
        "autoWidth": false,
        "stateSave": true,
        "language": {
            "processing":     '<i class="fa fa-spin fa-circle-o-notch"></i>',
            "lengthMenu":     Translator.trans('dataTables.lengthMenu'),
            "zeroRecords":    Translator.trans('dataTables.zeroRecords'),
            "info":           Translator.trans('dataTables.info'),
            "infoEmpty":      Translator.trans('dataTables.infoEmpty'),
            "infoFiltered":   Translator.trans('dataTables.infoFiltered'),
            "infoPostFix":    Translator.trans('dataTables.infoPostFix'),
            "search":         Translator.trans('dataTables.search'),
            "loadingRecords": Translator.trans('dataTables.loadingRecords'),
            "paginate": {
                "first":    Translator.trans('dataTables.paginate.first'),
                "previous": Translator.trans('dataTables.paginate.previous'),
                "next":     Translator.trans('dataTables.paginate.next'),
                "last":     Translator.trans('dataTables.paginate.last')
            },
        },

        "rowCallback": function( row, data ) {
            if (data['class']) {
                $(row).addClass(data['class']);
            }
        }
    };

    // -------------------------------
    // Panel Collapses
    // -------------------------------
    $('a.panel-collapse').click(function() {
        $(this).closest(".panel-heading").next().toggleClass('open');
    });

    var showFilters = JSON.parse(sessionStorage.getItem('dataTable.list.showFilters'));

    $('#filterForm .panel-collapse').on('click', function(){
        sessionStorage.setItem('dataTable.list.showFilters', JSON.stringify($('#filterForm .panel-body').is('.open')));
    });

    if (showFilters == false) {
        $('#filterForm .panel-collapse').trigger('click');
    };

    $('.datatables').each(function() {
        var columns = [],
            datatable = null,
            firstOrderableColumns = null,
            idx = 0,
            table = $(this),
            dom = table.data('dom'),
            localOptions = {
                "dom": dom == 'simple' ? dataTablesOptions.simpleDom : (dom ? dom :dataTablesOptions.dom),
                "columns": table.data('columns'),
                "ajax": {
                    "url": table.data('source'),
                    "type": 'POST',
                    "deferRender": true,
                    "data": $('form#filterForm').serializeObject(),
                    "error": function (xhr, error, thrown) {
                        $('.alert',table.parents('.panel-body')).replaceWith(
                            '<div class="alert alert-block alert-danger">'+
                            '<strong>' + thrown + '</strong> '+
                            xhr.responseJSON[0]['message'] +
                            '</div>'
                        )
                    }
                },
                "infoCallback": function (settings, start, end, max, total, pre) {
                    // In case we are on lower than expected (filtering decreased amount of rows compared to datatable context)
                    if (settings._iDisplayStart + 1 > settings._iRecordsDisplay) {
                        // start number must be re-processed
                        var newStart = settings._iDisplayLength * (Math.floor(settings._iRecordsDisplay / settings._iDisplayLength)) + 1;
                        pre = pre.replace(" "+start+" ", " "+newStart+" ");
                    }

                    return pre;
                }
            }
        ;

        if (table.data('disableSort')) {
            localOptions.ordering = false;
        }

        if (table.data('source')) {
            localOptions.stateSaveCallback= function (settings, data) {
                sessionStorage.setItem('dataTable.' + table.data('source'), JSON.stringify(data));
            };
            localOptions.stateLoadCallback= function (settings) {
                return JSON.parse(sessionStorage.getItem('dataTable.' + table.data('source')));
            };
        }

        if (table.data('searchable') == 0) {
            localOptions.searching = false;
        };

        if (table.data('orderFixed')) {
            localOptions.orderFixed = table.data('orderFixed');
        } else {
            localOptions.order = table.data('order');
        }

        if (table.data('pageLength')) {
            localOptions.pageLength = table.data('pageLength');
        } else {
            localOptions.paging = false;
        }

        if (table.data('ajaxData')) {
            localOptions.ajax.data += '&' + $.param(table.data('ajaxData'));
        }

        datatable = $(this).dataTable($.extend({}, dataTablesOptions, localOptions));

        datatable.on('init.dt', function() {
            var wrapper = table.parents('.dataTables_wrapper').first();

            $(wrapper).addClass('form-inline dt-bootstrap');
            $('.dataTables_filter input', wrapper).addClass('form-control input-sm').attr('placeholder', Translator.trans('dataTables.search_placeholder'));
            $('.dataTables_length select', wrapper).addClass('form-control input-sm');
        });

        datatable.on('preXhr.dt', function ( e, settings, data ) {
            $('tbody', table).css('opacity', '0.5')
            data.sessionId = $('#sessionId').val();
        });

        datatable.on('draw.dt', function(e, settings) {
            $('tbody tr:first-child .move-top, tbody tr:first-child .move-up, tbody tr:last-child .move-down, tbody tr:last-child .move-bottom', datatable).addClass('disabled');

            $('.alert-loading').fadeOut(400, function() {
                $(table).parents('.datatables-container').fadeIn();
            });
            $('tbody', table).css('opacity', '1')
        });

        datatable.on('click', '.move-top', $(this), function(e) {
            e.preventDefault();

            var row = datatable.api().row($(this).closest('tr'));
            var index = row.index();

            if (index > 0) {
                $(this).btn('loading');
                updatePosition(row.data().id, 1, datatable);
            }
        });

        datatable.on('click', '.move-up', $(this), function(e) {
            e.preventDefault();

            var row = datatable.api().row($(this).closest('tr'));
            var index = row.index();

            if (index > 0) {
                $(this).btn('loading');
                updatePosition(row.data().id, index, datatable);
            }
        });

        datatable.on('click', '.move-down', $(this), function(e) {
            e.preventDefault();
            var row = datatable.api().row($(this).closest('tr'));
            var index = row.index();

            if (index < datatable.api().data().length) {
                $(this).btn('loading');
                updatePosition(row.data().id, index+2, datatable);
            }
        });

        datatable.on('click', '.move-bottom', $(this), function(e) {
            e.preventDefault();

            var row = datatable.api().row($(this).closest('tr'));
            var index = row.index();

            if (index < datatable.api().data().length) {
                $(this).btn('loading');
                updatePosition(row.data().id, datatable.api().data().length + 1, datatable);
            }
        });

        datatable.on('click', '.remove', $(this), function(e) {
            e.preventDefault();

            var row = datatable.api().row($(this).closest('tr')),
                route = datatable.data('routePrefix') + 'delete',
                id = row.data().id
            ;

            if (datatable.data('routeParameters')) {
                var url = Routing.generate(route, $.extend({}, datatable.data('routeParameters'), { 'sortItem': id }));
            } else {
                var url = Routing.generate(route,  { 'sortItem': id });
            }

            $.post(url, { '_method': 'DELETE'}, function() {
                datatable.api().draw();
            });
        });

        datatable.closest('.many-to-many-table').on('click', '.add', $(this), function(e) {
            e.preventDefault();

            var select = $(this).closest('.many-to-many-table').find('select'),
                items = select.val(),
                route = datatable.data('routePrefix') + 'new',
                url = null
            ;

            if (datatable.data('routeParameters')) {
                url = Routing.generate(route, datatable.data('routeParameters'));
            } else {
                url = Routing.generate(route);
            }

            $.post(url, { 'items': items }, function() {
                $(select).select2("val", "")
                for (var i = 0; i < items.length; i++) {
                    $('option[value="'+items[i]+'"]', select).remove();
                };

                datatable.api().draw();
            });
        });


        $('tbody', datatable).on('click', 'td.expand-child', $(this), function (e) {
            e.preventDefault();
            e.stopPropagation();

            var tr = $(this).parents('tr');
            var icon = $('i', this);
            var row = datatable.api().row( tr );

            if ( row.child.isShown() ) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
                icon.toggleClass('fa-plus-square fa-minus-square');
                datatable.trigger('child-hide.dt');
            }
            else {
                // Open this row
                row.child( row.data().child ).show();
                tr.addClass('shown');
                icon.toggleClass('fa-plus-square fa-minus-square');
                datatable.trigger('child-show.dt');
            }
        });

    });

    function updatePosition(id, wantedIndex, datatable) {
        var data = {
            'position': wantedIndex,
        };

        var route = datatable.data('routePrefix') + 'sort';

        if (datatable.data('routeParameters')) {
            var url = Routing.generate(route, $.extend({}, datatable.data('routeParameters'), { 'sortItem': id }));
        } else {
            var url = Routing.generate(route,  { 'sortItem': id });
        }

        $.post(url, data, function() {
            datatable.api().draw();
        });
    }

    $('.toggle').each(function() {
        var options = {
            on: $(this).hasClass('on'),
            text: {
                on: Translator.trans('toggles.on'),
                off: Translator.trans('toggles.off')
            }
        };
        if ($(this).data('checkbox')) {
            options.checkbox = $(this).data('checkbox');
        }
        $(this).toggles(options)
    });

    $('[rel="tooltip"]').tooltip();

    $('.multiselect').multiSelect();

    dateRangeLocale = {
        applyLabel: Translator.trans('dateRangePicker.applyLabel'),
        cancelLabel: Translator.trans('dateRangePicker.cancelLabel'),
        fromLabel: Translator.trans('dateRangePicker.fromLabel'),
        toLabel: Translator.trans('dateRangePicker.toLabel'),
        weekLabel: Translator.trans('dateRangePicker.weekLabel'),
        customRangeLabel: Translator.trans('dateRangePicker.customRangeLabel'),
        daysOfWeek: moment()._lang._weekdaysMin.slice(),
        monthNames: moment()._lang._monthsShort.slice(),
        firstDay: Translator.trans('dateRangePicker.firstDay'),
    };

    moment.lang($('html').prop('lang'));


    $(document).on('click.daterangepicker', '.daterange', function (evt) {
        var $this = $(this),
            options = {
                'format': $this.data('format'),
                'locale': dateRangeLocale,
                'startDate': moment().format($this.data('format')),
                'timePicker12Hour' : $this.data('timePickerHour')
            }
        ;

        if ($this.data('singleDatePicker')) {
            options.singleDatePicker = true;
        }

        if ($this.data('timePicker')) {
            options.timePicker = true;
        }

        if ($this.data('opens')) {
            options.opens = $this.data('opens');
        }

        if ($this.data('startDate')) {
            options.startDate = $this.data('startDate');
        }

        if ($this.data('endDate')) {
            options.endDate = $this.data('endDate');
        }

        if ($this.data('timePickerIncrement')) {
            options.timePickerIncrement = $this.data('timePickerIncrement');
        }

        if (!$this.data('daterangepicker')) {
            $this.data('daterangepicker', $this.daterangepicker(options));
            $this.trigger('click');
        }
    });

    $(document)
        .on('change', '.btn-file :file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
    });

    $('.btn-file :file').on('fileselect', function(event, numFiles, label) {
        var parent = $(this).parents('.input-group');
        if (numFiles === 1) {
            $('input[type="text"]', parent).val(label);
        } else {
            $('input[type="text"]', parent).val(Translator.trans('upload_file.file.x_files_selected', {'count': numFiles}));
        }
    });
});

$(function () {
    $(document).on('ready', function (e) {
        var nbTab = ($('.tab-left>.nav>li,.tab-right>.nav>li').length);

        $('.tab-left > .tab-content,.tab-right > .tab-content').css('min-height', (nbTab * 42) + 'px');
    });
});

// Fix elfinder
(function ($, undefined) {
    var btn = $.fn.button.noConflict(); // reverts $.fn.button to jqueryui btn
    $.fn.btn = btn; // assigns bootstrap button functionality to $.fn.btn
})(jQuery);
